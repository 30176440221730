<template>
  <div class="search-group">
    <b-input
      v-model="valueInner"
      name="inputSearch"
      class="search-items"
      :class="{ 'fonte-menor' : fonteMenor }"
      :placeholder="placeholder"
      autofocus
      debounce="800"
    />
    <!-- @input="sendToParent" -->
    <span
      class="icon-delete"
      @click="cleanInput"
    ><font-awesome-icon icon="fa-solid fa-xmark" /></span>
    <label
      name="labelSearch"
      for="inputSearch"
      class="fas fa-search search icon"
    />
  </div>
</template>

<style lang="scss">
///search
.search-items {
  color: #5d5b5b;
  padding-left: calc(0.05rem + 0.5rem + 1rem + 0.2rem);
  padding-right: calc(0.1rem + 0.1rem + 1rem + 0.2rem);
  &:focus {
    border-color: rgba(24, 28, 33, 0.1);
  }
}

.fonte-menor {
    font-size: 0.94rem;
  }

.search-group {
  position: relative;

  & .icon {
    position: absolute;
    padding: 0.05rem;
    left: 0.5rem;
    top: calc(
      50% - 0.5em
    ); /* Keep icon in center of input, regardless of the input height */
    color: #7c7a7a;
  }
  & .icon-delete {
    position: absolute;
    padding: 0.05rem;
    right: 0.5rem;
    top: calc(50% - 0.7em);
    cursor: pointer;
    color: #7c7a7a;
  }
}

.search-group label.search-placeholder {
  top: calc(50% - 0.6rem);
  padding-left: 1.5rem;
  text-align: top;
  font-weight: 500;
  color: rgba(124, 122, 122, 0.4117647058823529);
}
</style>

<script>
// import { debounce } from 'debounce'

export default {
  name: 'InputSearch',
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Busca por palavra chave',
    },
    fonteMenor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valueInner: this.value,
    }
  },
  watch: {
    valueInner(value, oldValue) {
      if (value !== oldValue) {
        this.$emit('input', value)
        this.$emit('filtrar', value)
      }
    },
  },
  methods: {
    cleanInput() {
      this.valueInner = null
      this.$emit('clean', this.valueInner)
    },
    // buscaInput: debounce(function (e) {
    //   this.valueInner = e
    //   this.$emit('input', this.valueInner)
    //   this.$emit('filtrar', this.valueInner)
    // }, 1500),
    // sendToParent() {
    //   this.$emit('input', this.valueInner)
    //   this.$emit('filtrar', this.valueInner)
    // },
  },
}
</script>
